input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scrollbar-container.cs-message-list__scroll-wrapper.ps{
  padding: 0 12px;
}

.cs-message-input{
  position: sticky !important;
  bottom: 0;
}